import {getAuth, signInAnonymously} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {useState} from "react";
import {FIREBASE_CONFIG} from "../util/constants";
import { handleError } from  "helpers/sentry";

const app = initializeApp(FIREBASE_CONFIG);

function FirebaseProvider({children}) {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const auth = getAuth(app);
  signInAnonymously(auth)
    .then(() => {
      setIsAuthenticated(true);
    })
    .catch((error) => {
      handleError(error);
    });

  return isAuthenticated ? children : null;
}

export default FirebaseProvider;
