import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {styles} from '../../common/styles/custom.js';
import parameters from '../../util/parameters';
import queryString from 'query-string';
import {FormControl} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import _ from 'lodash';
import CircularLoader from '../page_objects/circularLoader';
import Grid2 from "@mui/material/Unstable_Grid2";
import {FormControlLabel, Switch} from "@mui/material";
import { openSnackbar } from "../page_objects/snackbar";
import {axiosWrapper} from "../../util/axiosWrapper";
import { handleError } from 'helpers/sentry.js';

const params = parameters.getInstance();


const queryParams = queryString.parse(location.search);

export default class ProctoringSettingsIframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component_mounted: false,
      content_id: '',
      course_id: '',
      proview_token: [{
        value: '',
        label: '',
        proctor_type: [
          {
            label: '',
            value: '',
          }
        ]
      }],
      quiz_payload: {
        action: 0,
        quiz_id: '',
        course_id: '',
        proview_token: '',
        proctoring_type: '',
        tsb_enabled: false,
        candidate_instructions: '',
        proctor_instructions: '',
      }
    };

  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      component_mounted: false,
    });

    try{
      await axiosWrapper({
        method: "POST",
        url: "lti/blackboard/settings/content",
        query: { nonce: params.getNonce() },
        body: this.state.quiz_payload,
        headers: {
          'Content-Type': 'application/json',
        }
      });
      openSnackbar({message: 'Proctoring Settings Updated Successfully', severity: 'success', alert: true});
    } catch (error) {
      handleError(error);
      openSnackbar({message: 'Something went wrong', severity: 'error', alert: true})
    } finally {
      this.setState({
        component_mounted: true,
      })
    }
  }

  async componentDidMount() {
    const hash = queryParams.hash;
    const payload = JSON.parse(atob(hash));
    if (payload?.nonce && !params.getNonce()) {
      params.setNonce(payload.nonce);
    }
    this.setState({
      content_id: payload.content_id,
      course_id: payload.course_id,
      quiz_payload: {
        ...this.state.quiz_payload,
        quiz_id: payload.content_id,
        course_id: payload.course_id
      }
    }, () => {
    });
    const proctoringTokens = await axiosWrapper({
      method: 'GET',
      url: 'proview/token',
      query: { nonce: `${params.getNonce()}` }
    });

    this.setState({
      proview_token: proctoringTokens.map(token => {
        return {
          value: token.token,
          label: token.name,
          proctor_type: _.filter([
            {
              label: 'AI Proctoring',
              value: 'ai_proctor',
              enabled: token?.proctor_types?.ai_proctor,
            },
            {
              label: 'Live Proctoring',
              value: 'live_proctor',
              enabled: token?.proctor_types?.live_proctor,
            },
            {
              label: 'Record and Review',
              value: 'record_and_review',
              enabled: token?.proctor_types?.record_and_review,
            }
          ], 'enabled'),
        }
      })
    });

    let proctoring_settings = {}
    try {
      proctoring_settings = await axiosWrapper({
        method: 'GET',
        url: 'quiz',
        query: {
          nonce: params.getNonce(),
          quiz_id: payload.content_id,
        }
      });
    } catch (e) {
      if(e?.status_code !== 404) {
        throw(e)
      }
    }
    if (proctoring_settings?.data) {
      this.setState({
        quiz_payload: {
          ...this.state.quiz_payload,
          action: 1,
          proview_token: proctoring_settings.data.proview_token,
          proctoring_type: proctoring_settings.data.proctoring_type,
          tsb_enabled: proctoring_settings.data.tsb_enabled,
          candidate_instructions: proctoring_settings.data.candidate_instructions,
          proctor_instructions: proctoring_settings.data.proctor_instructions,
        }
      });
    }
    this.setState({
      component_mounted: true,
    })
  }

  render() {
    return (
      <div>
        <CircularLoader component_mounted={this.state.component_mounted}>
          <FormControl
            fullWidth={true}
          >
            <Box component='form'
              // sx={{
              //   '& .MuiTextField-root': { m: 1, width: '25ch' },
              // }}
                 display="flex"
                 justifyContent="center"
                 marginX="auto"
                 width="80%"
                 noValidate
                 autoComplete='off'
                 onSubmit={this.handleSubmit}
            >
              <Grid2 container spacing={2} columns={12}>
                <Grid2 item xs={12}/>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='course-id'
                    className='outlined-input'
                    label='Course ID'
                    value={this.state.course_id}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid2>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='content-id'
                    className='outlined-read-only-input'
                    label='Test ID'
                    value={this.state.content_id}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid2>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='proview-token'
                    className='outlined-select'
                    select
                    label='Proview Token'
                    value={this.state.quiz_payload.proview_token}
                    fullWidth
                    onChange={(event) => {
                      this.setState({selected_proview_token: event.target.value});
                      this.setState({
                        quiz_payload: {
                          ...this.state.quiz_payload,
                          proview_token: event.target.value
                        }
                      });
                    }}
                  >
                    {this.state.proview_token.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='proctor-type'
                    className='outlined-select'
                    select
                    label='Proctor Type'
                    disabled={!this.state.quiz_payload.proview_token}
                    value={this.state.quiz_payload.proctoring_type}
                    fullWidth
                    onChange={(event) => {
                      this.setState({
                        quiz_payload: {
                          ...this.state.quiz_payload,
                          proctoring_type: event.target.value
                        }
                      });
                    }}
                  >
                    {this.state.proview_token?.find(token => token.value === this.state.quiz_payload.proview_token)?.proctor_type?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='candidate-instructions'
                    className='outlined-input'
                    label='Candidate Instructions'
                    value={this.state.quiz_payload.candidate_instructions}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(event) => {
                      this.setState({
                        quiz_payload: {
                          ...this.state.quiz_payload,
                          candidate_instructions: event.target.value
                        }
                      });
                    }}
                  />
                </Grid2>
                <Grid2 item xs={6}>
                  <TextField
                    required
                    id='proctor-instructions'
                    className='outlined-input'
                    label='Proctor Instructions'
                    value={this.state.quiz_payload.proctor_instructions}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(event) => {
                      this.setState({
                        quiz_payload: {
                          ...this.state.quiz_payload,
                          proctor_instructions: event.target.value
                        }
                      });
                    }}
                  />
                </Grid2>
                <Grid2 item xs={12}/>
                <Grid2 item xs={12}>
                  <FormControlLabel
                    disabled
                    control={<Switch defaultChecked={this.state.quiz_payload.tsb_enabled}/>}
                    label="Secure Browser"
                    labelPlacement="start"
                    onChange={(event) => {
                      this.setState({
                        quiz_payload: {
                          ...this.state.quiz_payload,
                          tsb_enabled: event.target.checked
                        }
                      });
                    }}
                  />
                </Grid2>
                <Grid2 item xs={12} style={{'justifyContent': "center", display: "flex"}}>
                  <Button
                    variant='contained'
                    size='large'
                    className={styles.button}
                    type='submit'
                  >
                    Submit
                  </Button>
                </Grid2>
              </Grid2>
            </Box>
          </FormControl>
        </CircularLoader>
      </div>
    );
  }
}