import React from "react";
import parameters from "../../util/parameters";
import {MESSAGE_EVENT_TYPES, MESSAGE_TYPES} from "../../util/constants";
const params = parameters.getInstance();

export default class MessageHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course_id: '',
      content_id: '',
      correlation_id: '',
    };
    this.token = props.token;
    this.proctoring_handle = props.proctoring_handle;
    this.correlation_id_prefix = 'proview-settings-panel';
    this.messageChannel = props.messageChannel;

  }

  componentDidMount() {
    this.messageChannel.onmessage = this.onMessageFromUltra;
    this.messageChannel.postMessage({
      type: 'authorization:authorize',
      token: this.token
    });
  }

  onMessageFromUltra = (message) => {
    switch(message.data.type) {
      case MESSAGE_TYPES.AUTHORIZATION_AUTHORIZE:
        this.onAuthorizedWithUltra();
        break;
      case MESSAGE_TYPES.EVENT_EVENT:
        this.onEventFromUltra(message);
        break;
      case MESSAGE_TYPES.PORTAL_PANEL_RESPONSE:
        if (message.data.status === 'success') {
          this.renderPanelContents(message);
        }
        break;
      // case 'proctoring-service:register':
      //   console.log(message.data.proctoringPlacementHandle);
      //   console.log(message.data.status);
      //   console.log(message.data.errorMessage);
      //   break;
      // case 'proctoring-service:settings-saved':
      // // @todo: Add a check for proctoring placement handle id (To ensure it works properly in case of multiple proctoring placements)
      // // Settings were saved for this contentId
      // console.log(message.data);
      // console.log(message.data.contentId);
      // // Status of proctoring
      // console.log("Status Of Proctoring: ", message.data.enabled);
      // if (message.data.enabled) {
      //  // console.log("Proctoring Enabled");
      // }
      // messageChannel.postMessage({
      //   type: 'proctoring-service:settings-saved:response',
      //   correlationId: message.data.correlationId,
      //   success: true,
      //   error: undefined
      // });
      // break;
    }
  }

  onAuthorizedWithUltra = () => {

    this.messageChannel.postMessage({
      type: 'event:subscribe',
      subscriptions: [MESSAGE_EVENT_TYPES.CLICK, MESSAGE_EVENT_TYPES.ROUTE, MESSAGE_EVENT_TYPES.PORTAL_NEW], // 'portal:remove', 'hover', 'proctoring-service:settings-saved'
    });
    console.log("Proctoring Handle: ", this.proctoring_handle);
    this.messageChannel.postMessage({
      type: 'proctoring-service:register',
      proctoringPlacementHandle: this.proctoring_handle
    });
  }

  onEventFromUltra = (message) => {
    switch (message.data.eventType) {
      case MESSAGE_EVENT_TYPES.ROUTE:
        if (['base.admin.peek.course.outline.peek.gradebook-item.assessment','base.courses.peek.course.outline.peek.gradebook-item.assessment', 'base.recentActivity.peek.course.outline.peek.gradebook-item.assessment'].includes(message.data.routeName)) {
          this.setState({
            course_id: message.data.routeData.courseId,
            content_id: message.data.routeData.contentId
          })
        }
        break;
      case MESSAGE_EVENT_TYPES.PORTAL_NEW:
        if (message.data.selector === 'course.content.assessment.settings.proctoring.panel.settings') {
          this.setState({
            correlation_id: this.correlation_id_prefix + ':' + message.data.selectorData.contentId
          });

          this.messageChannel.postMessage({
            type: "portal:panel",
            correlationId: this.state.correlation_id,
            panelTitle: 'Additional Proctoring Settings',
            panelType: 'full' //'full' //'small'
          });
        }
        break;
    }
  }
  renderPanelContents = (message) => {
    const nonce = params.getNonce();
    const payload = {
      content_id: this.state.content_id,
      course_id: this.state.course_id,
      nonce
    }

    const hash = btoa(JSON.stringify(payload));
    if (message.data.correlationId === this.state.correlation_id) {
      const panelId = message.data.portalId;
      this.messageChannel.postMessage({
        type: 'portal:render',
        portalId: message.data.portalId,
        contents: {
          tag: 'span',
          props: {
            style: {
              display: 'flex',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'stretch',
            },
          },
          children: [{
            tag: 'iframe',
            props: {
              style: {flex: '1 1 auto'},
              src: `${window.location.protocol}//${window.location.hostname}/iframe_panel?hash=${hash}`,
            },
          }]
        },
      });
    }
  }

  render() {
    return (<></>);
  }
}