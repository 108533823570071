import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/index';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar/index';
import {withStyles} from '@material-ui/core/styles/index';
import {Alert} from "@mui/material";

let openSnackbarFn;

const styles = theme => ({
  close: {
    padding: theme.spacing / 2
  }
});

class SimpleSnackbar extends React.Component {
  state = {
    open: false,
    message: ''
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({message, severity, alert}) => {
    this.setState({
      open: true,
      message,
      severity,
      alert
    });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false, message: ''});
  };

  render() {
    return (
      <div>
        < Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          color={'secondary'}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.severity}
            variant='filled'
            sx={{width: "100%"}}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export function openSnackbar({message, severity = 'success', alert = false}) {
  openSnackbarFn({message, severity, alert});
}

export default withStyles(styles)(SimpleSnackbar);
