export const MESSAGE_TYPES = {
  AUTHORIZATION_AUTHORIZE: "authorization:authorize",
  EVENT_EVENT: "event:event",
  PORTAL_PANEL_RESPONSE: "portal:panel:response",
};

export const MESSAGE_EVENT_TYPES = {
  CLICK: "click",
  ROUTE: "route",
  PORTAL_NEW: "portal:new",
};

export const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const PROVIEW_WRAPPER_BASE_URL = process.env.PROVIEW_WRAPPER_BASE_URL || "https://pages.talview.com/proview-wrapper";

export const SENTRY_CONFIG = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: +(process.env.SENTRY_TRACES_SAMPLE_RATE || 0.2),
  replaysSessionSampleRate: +(process.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.1),
  replaysOnErrorSampleRate: +(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.1),
  sentryRelease: process.env.SENTRY_RELEASE,
  sampleRate: +(process.env.SENTRY_SAMPLE_RATE || 0.2),
  sentryAuthToken: process.env.SENTRY_AUTH_TOKEN,
}
