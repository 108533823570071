import React from "react";
import Typography from "@material-ui/core/Typography";
import parameters from "../../util/parameters";
import {getDatabase, ref, set} from "firebase/database";
import {initializeApp} from "firebase/app";
import {FIREBASE_CONFIG} from "../../util/constants";
import {axiosWrapper} from "../../util/axiosWrapper";
import { handleError } from "helpers/sentry";

const app = initializeApp(FIREBASE_CONFIG);
const database = getDatabase(app);
const Buffer = require("safe-buffer").Buffer;
const params = parameters.getInstance();

/**
 * We should see this view if one of the following has happened:
 * 1. An error occurred while student was taking assessment
 * 2. The attempt has been submitted and we specified end_assessment_return=true in LtiStartAssessment
 */
export default class ProctoringEndOptionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const proctoringServicePayload = await axiosWrapper({
      method: "GET",
      url: "lti/blackboard/proctoring",
      query: { nonce: params.getNonce() }
    });
    console.log(proctoringServicePayload.body);
    const email = proctoringServicePayload?.body?.email;
    const content_id = proctoringServicePayload?.body["https://purl.imsglobal.org/spec/lti/claim/resource_link"]?.id;
    const encoded_email = Buffer.from(email).toString("base64");
    const sessionId = content_id + "_" + encoded_email;
    const sessionsRef = ref(database, "sessions/" + sessionId);
    const sessionData = {
      proctoring_started: false,
      assessment_status: "ended"
    };
    set(sessionsRef, sessionData)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        handleError(error);
      });
    this.setState({
      header: proctoringServicePayload.header,
      body: proctoringServicePayload.body,
    });
  }

  render() {
    return (
      <div>
        <Typography variant='h5' gutterBottom color='textPrimary' align='center'>
          Assessment Completed
        </Typography>
        <Typography variant='body1' align='center' color='textSecondary' gutterBottom>
          Thank you for attending the assessment. You can now close the proctoring tab before returning to home page.
        </Typography>
        {/* <br/>*/}
        {/* <div style={{ marginBottom: '30px' }}>*/}
        {/*  <div className='row' style={{display: 'flex', justifyContent: 'center'}}>*/}
        {/*    <form action='buildProctoringEndReturnPayload' method='POST'>*/}
        {/*      <input type='hidden' name='nonce' value={params.getNonce()}/>*/}
        {/*      <input type='submit' value='Return to Test Start'/>*/}
        {/*    </form>*/}
        {/*  </div>*/}
        {/* </div>*/}
      </div>
    );
  }
}
