import React from "react";
import Typography from "@material-ui/core/Typography";
import parameters from "../../util/parameters";
import {initializeApp} from "firebase/app";
import {FIREBASE_CONFIG} from "../../util/constants";
import {getDatabase, onValue, ref, set} from "firebase/database";
import queryString from "query-string";
import {axiosWrapper} from "../../util/axiosWrapper";
import {handleError} from "helpers/sentry";

const app = initializeApp(FIREBASE_CONFIG);
const database = getDatabase(app);
const params = parameters.getInstance();
const Buffer = require("safe-buffer").Buffer;

const queryParams = queryString.parse(location.search);

if (queryParams?.nonce && !params.getNonce()) {
  const nonce = queryParams.nonce;
  params.setNonce(nonce);
}
export default class ProctoringEndActionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const proctoringServicePayload = await axiosWrapper({
      method: "GET",
      url: "lti/blackboard/proctoring",
      query: { nonce: params.getNonce() }
    });
    const email = proctoringServicePayload?.body?.email;
    const content_id = proctoringServicePayload?.body["https://purl.imsglobal.org/spec/lti/claim/resource_link"]?.id;
    const encoded_email = Buffer.from(email).toString("base64");
    const sessionId = content_id + "_" + encoded_email;
    const sessionsRef = ref(database, "sessions/" + sessionId);
    const sessionData = {
      proctoring_started: true,
      assessment_status: "started"
    };
    set(sessionsRef, sessionData)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    onValue(sessionsRef, snapshot => {
      const sessionData = snapshot.val();
      if (sessionData?.proctoring_started === false && sessionData?.assessment_status === "ended") {
        console.log("Proctoring has ended");
        parent.postMessage({exit: true}, "*");
      }
    }, error => {
      handleError(error);
    });
  }


  render() {
    return (
      <div style={{margin: "auto", width: "60%", padding: "10px"}}>
        <Typography variant='h5' gutterBottom color='textPrimary' align='center'>
          Proctoring initiated
        </Typography>
        <Typography variant='body1' color='textSecondary' align='center'>
          Do not close this tab until the end of assessment. Otherwise your assessment will not be qualified.
          Go to your assessment tab to proceed to the assessment.
        </Typography>
        <Typography variant='body2' align='right justify' color='textSecondary' gutterBottom>
          Note: <br/>
          <b>1.</b> Please keep the device speakers un-muted. The proctor might try to reach you via VOIP call.<br/>
          <b>2.</b> If required you can reach out to proctor via chat or call by coming to this tab.<br/>
        </Typography>
      </div>
    );
  }
}
