import React from "react";
import {Typography} from "@material-ui/core";
import parameters from "../../util/parameters";
import {getDatabase, onValue, ref} from "firebase/database";
import {initializeApp} from "firebase/app";
import {FIREBASE_CONFIG, PROVIEW_WRAPPER_BASE_URL} from "../../util/constants";
import CircularLoader from "../page_objects/circularLoader";
import {axiosWrapper} from "../../util/axiosWrapper";
import {handleError} from "helpers/sentry"

const Buffer = require("safe-buffer").Buffer;

const app = initializeApp(FIREBASE_CONFIG);
const database = getDatabase(app);
const params = parameters.getInstance();

export default class ProctoringServiceOptionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component_mounted: false,
    };
  }

  async componentDidMount() {
    let base64hash;
    const body = {
      nonce: params.getNonce(),
      end_assessment_return: true,
    };
    const proctoringServicePayload = await axiosWrapper({
      method: "GET",
      url: "lti/blackboard/proctoring/start",
      query: body,
    });

    const proctoringSettings = await axiosWrapper({
      method: "GET",
      url: "quiz",
      query: {
        nonce: params.getNonce(),
        quiz_id: proctoringServicePayload?.body?.["https://purl.imsglobal.org/spec/lti/claim/resource_link"]?.id
      }
    });
    console.log("Proctoring settings: ", proctoringSettings);

    const lms_url = proctoringServicePayload?.body?.["https://purl.imsglobal.org/spec/lti/claim/tool_platform"]?.url;
    const browser_agent = navigator.userAgent;
    const attempt_no = proctoringServicePayload?.decodedJwt?.payload?.["https://purl.imsglobal.org/spec/lti-ap/claim/attempt_number"];
    const email = proctoringServicePayload?.body?.email;
    const content_id = proctoringServicePayload?.body?.["https://purl.imsglobal.org/spec/lti/claim/resource_link"]?.id;
    // todo: use toBase64 from helpers instead of Buffer
    const encoded_email = Buffer.from(email).toString("base64");
    const wrapper_payload = {
      profile_id: email,
      session_id: proctoringSettings?.data?.proctoring_type === "live_proctor" ? `${content_id}-${email}` : `${content_id}-${email}-${attempt_no}`,
      proview_token: proctoringSettings?.data?.proview_token,
      session_type: proctoringSettings?.data?.proctoring_type,
      is_tsb_required: proctoringSettings?.data?.tsb_enabled,
      open_in_new_tab: "no",
      exam_url: `${proctoringServicePayload.service_url}/launch_proview?nonce=${params.getNonce()}`,
      redirect_url: "",
      additional_instruction: proctoringSettings?.data?.candidate_instructions,
    };
    const launch_tsb = wrapper_payload.is_tsb_required && !browser_agent.includes("Proview-SB");
    base64hash = Buffer.from(JSON.stringify(wrapper_payload), "utf8").toString("base64");
    const exam_launch_url = launch_tsb
      ? `https://pages.talview.com/securebrowser/?redirect_url=${lms_url}`
      : `${PROVIEW_WRAPPER_BASE_URL}/launch/index.html?hash=${base64hash}`;
    this.setState({
      jwt: proctoringServicePayload.jwt,
      decodedJwt: proctoringServicePayload.decodedJwt,
      startAssessmentUrl: proctoringServicePayload.start_assessment_url,
      returnUrl: proctoringServicePayload.return_url,
      hash: base64hash,
      exam_launch_url: exam_launch_url,
      launch_tsb: launch_tsb,
    });
    const sessionId = `${content_id}_${encoded_email}`;
    const sessionsRef = ref(database, "sessions/" + sessionId);
    let proctoringStarted = false;
    onValue(sessionsRef, snapshot => {
      const sessionData = snapshot.val();
      proctoringStarted = (sessionData && sessionData?.proctoring_started === true);
      this.setState({
        proctoringStarted
      });

      this.setState({
        component_mounted: true,
      });
    }, error => {
      handleError(error);
    });
  }

  render() {
    return (
      <CircularLoader component_mounted={this.state.component_mounted}>
        <div>
          <div className='row' style={{margin: "auto", width: "60%", padding: "10px"}}>
            {
              this.state.launch_tsb ?
                (<div>
                  <Typography variant='h4' align='center' color='textPrimary' gutterBottom>
                    Restricted Exam
                  </Typography>
                  <Typography variant='body1' align='center' color='textSecondary' gutterBottom>
                    This exam is restricted to open only in Secure Browser. <br/>
                    Click &apos;Launch SB&apos; to download/launch the browser.
                  </Typography>
                </div>) :
                this.state.proctoringStarted ?
                  (<div>
                    <Typography variant='h4' align='center' color='textPrimary' gutterBottom>
                      Your assessment is proctored
                    </Typography>
                    <Typography variant='body1' align='center' color='textSecondary' gutterBottom>
                      Click &apos;Start Assessment&apos; to attend the assessment.
                    </Typography>
                    <br />
                  </div>) :
                  (<div>
                    <Typography variant='h4' align='center' color='textPrimary' gutterBottom>
                      Welcome to your proctored assessment
                    </Typography>
                    <Typography variant='body1' align='justify' color='textSecondary' gutterBottom>
                      Follow these steps for initial checks to proceed to the assessment.<br/>
                      <b>Step 1:</b> Click &apos;Start Proctoring&apos;  to proceed to proctoring checks.<br/>
                      <b>Step 2:</b> Complete each step in the new tab as instructed.<br/>
                      <b>Step 3:</b> Return to this tab to begin the assessment.<br/>
                      <br/>
                    </Typography>
                  </div>)
            }
            {this.state.launch_tsb ?
              <br/> :
              (<div>
                <Typography variant='body2' align='justify' color='textSecondary' gutterBottom>
                  Note: <br/>
                  <b>1.</b> Please keep the device speakers un-muted. The proctor might try to reach you via VOIP
                  call.<br/>
                  <b>2.</b> If required you can reach out to proctor via chat or call by going to the proctoring
                  tab.<br/>
                </Typography>
                <br/>
              </div>)
            }
            <div className='row' style={{display: "flex", justifyContent: "center"}}>
              {
                this.state.launch_tsb ?
                  (<button className='btn btn-primary'
                    onClick={() => window.open(`${this.state.exam_launch_url}`, "_blank")}>
                    Launch SB
                  </button>) :
                  this.state.proctoringStarted ? (
                    <form action={this.state.startAssessmentUrl} method='post'>
                      <input type='hidden' name='JWT' defaultValue={this.state.jwt}/>
                      <input type='submit' value='Start Assessment'/>
                    </form>) :
                    (<div>
                      <a href={this.state.exam_launch_url} target='_blank' rel="noopener noreferrer">
                          Start Proctoring
                      </a>
                      <br/>
                    </div>)
              }
            </div>
          </div>
        </div>
      </CircularLoader>
    );
  }
}
