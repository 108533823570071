import React from "react";
import MessageHandler from "./messageHandler";

export default class LoggedMessageChannel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.token = props.token;
    this.messageChannel = props.messageChannel;
    this.proctoring_handle = props.proctoring_handle;
    this.messageChannel.onmessage = this.onMessage;
  }

  onmessage = () => {
    console.log("Test onmessage");
  }

  onMessage = (evt) => {
    console.log(`[Proview] [UEF] From Learn Ultra:`, evt.data);
    this.onmessage(evt);
  };

  postMessage = (msg) => {
    console.log(`[Proview] [UEF] To Learn Ultra`, msg);
    this.messageChannel.postMessage(msg);
  }

  render() {
    return (<MessageHandler messageChannel={this.messageChannel} token={this.token} proctoring_handle={this.proctoring_handle}/>);
  }
}