import React from "react";
import Uef from "../page_objects/uef";
import parameters from "../../util/parameters";
import {axiosWrapper} from "../../util/axiosWrapper";

const params = parameters.getInstance();

export default class UefIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      lms_url: "",
      proctoring_handle: "",
    };
  }

  async componentDidMount() {
    console.log("UEF Integration: ", process.env.SERVER_URL)
    const {token, lms_url, proctoring_handle} = await axiosWrapper({
      method: "GET",
      url: "lti/blackboard/uef",
      query: { nonce: params.getNonce() },
    });
    console.log("UEF Integration Data: ", {token, lms_url});
    this.setState({
      token,
      lms_url,
      proctoring_handle,
    });
    console.log("UEF Integration State: ", this.state);
  }

  render() {
    return (
      (this.state.token && this.state.lms_url) ? (
        <Uef token={this.state.token} lms_url={this.state.lms_url} proctoring_handle={this.state.proctoring_handle}/>
      ) : (
        <></>
      ));
  }
}