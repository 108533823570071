import axios from "axios";
import {pickBy} from "lodash";
import {handleError} from "helpers/sentry";

export const axiosWrapper = async ({method, url, headers, query, body}) => {
  // console.log({method, url, headers, query, body});
  try {
    const config = {
      baseURL: process.env.SERVER_URL,
      method,
      url,
      headers,
      params: query,
      data: body
    };
    const {data} = await axios(pickBy(config));
    console.log({data});
    return data;
  } catch (error) {
    handleError(error);
  }
};
