import {CircularProgress} from "@material-ui/core";
import React from "react";

export default function CircularLoader(props) {
  // eslint-disable-next-line react/prop-types
  const {component_mounted, children} = props;
  return (
    !component_mounted ?
      (<div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <CircularProgress/>
      </div>) :
      (<div>
        {children}
      </div>)
  );
}