import AppBar from "@material-ui/core/AppBar/index";
import CssBaseline from "@material-ui/core/CssBaseline/index";
import ErrorBoundary from "../errorBoundary";
import ProctoringEndActionsView from "./proctoringEndActions";
import ProctoringEndOptionsView from "./proctoringEndOptions";
import ProctoringStartOptionsView from "./proctoringStartOptions";
import ProctoringSettingsIframe from "./proctoringSettingsIframe";
import UefIntegration from "./uefIntegration";
import PropTypes from "prop-types";
import React from "react";
import SnackBar from "../page_objects/snackbar";
import Toolbar from "@material-ui/core/Toolbar/index";
import Typography from "@material-ui/core/Typography/index";
import classNames from "classnames";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles/index";
import {Route} from "react-router-dom";
import {lightMode} from "../../common/styles/palette";
import {styles} from "../../common/styles/styles";

class Dashboard extends React.Component {

  render() {
    const {classes} = this.props;

    return (
      <MuiThemeProvider theme={lightMode}>
        <div className={classes.root}>
          <CssBaseline/>
          <AppBar
            position='absolute'
            className={classNames(
              classes.appBar,
            )}>
            <Toolbar
              className={classes.toolbar}>
              <Typography
                component='h1'
                variant='h6'
                color='secondary'
                style={{paddingLeft: "40px"}}
                noWrap
                className={classes.title}>
                Proview
              </Typography>
            </Toolbar>
          </AppBar>
          <ErrorBoundary>
            <main className={classes.content}>
              <div className={classes.appBarSpacer}/>
              <Route path='/proctoring_start_options_view' component={ProctoringStartOptionsView}/>
              <Route path='/proctoring_end_options_view' component={ProctoringEndOptionsView}/>
              <Route path='/launch_proview' component={ProctoringEndActionsView}/>
              <Route path='/launch_uef' component={UefIntegration}/>
              <Route path='/iframe_panel' component={ProctoringSettingsIframe}/>
            </main>
            <SnackBar/>
          </ErrorBoundary>
        </div>
      </MuiThemeProvider>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
