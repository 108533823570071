import Dashboard from "./components/pages/dashboard";
import React from "react";
import queryString from "query-string";
import parameters from "./util/parameters";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import FirebaseProvider from "./components/firebaseProvider";

const queryParams = queryString.parse(location.search);
let params = parameters.getInstance();

if (queryParams) {
  const nonce = queryParams.nonce;
  params.setNonce(nonce);
}

const App = () => {
  return (
    <BrowserRouter>
      <FirebaseProvider>
        <Switch>
          <Route path='/' component={Dashboard}/>
        </Switch>
      </FirebaseProvider>
    </BrowserRouter>
  )
}

export default App;