/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./app";
import { SENTRY_CONFIG } from "../src/util/constants";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";

Sentry.init({
  dsn: SENTRY_CONFIG.dsn,
  environment: SENTRY_CONFIG.environment,
  integrations: (integrations) => {
    return integrations
      .filter((integration) => integration.name !== "BrowserApiErrors")
      .concat([
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration()
      ]);
  },
  tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
  sampleRate: SENTRY_CONFIG.sampleRate,
  release: SENTRY_CONFIG.sentryRelease,
  replaysSessionSampleRate: SENTRY_CONFIG.replaysSessionSampleRate,
  replaysOnErrorSampleRate: SENTRY_CONFIG.replaysOnErrorSampleRate,
  ignoreErrors: ["AbortError: The play() request was interrupted"],
});

// createRoot(document.getElementById("root")).render(<App />);
ReactDOM.render(
  <App />, document.getElementById("root"));
