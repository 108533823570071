import React from "react";
import LoggedMessageChannel from "./loggedMessageChannel";

export default class Uef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      lms_url: props.lms_url,
      proctoring_handle: props.proctoring_handle,
      port: null
    };
  }

  componentDidMount() {
    window.addEventListener("message", this.onPostMessageReceived, false);
    window.parent.postMessage({"type": "integration:hello"}, this.state.lms_url + '/*');
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onPostMessageReceived, false);
  }

  onPostMessageReceived = (evt) => {
    const fromTrustedHost = evt.origin === window.__lmsHost || evt.origin === this.state.lms_url;
    console.log("Event: ", evt);
    if (!fromTrustedHost || !evt.data || !evt.data.type) {
      console.error('Message received from untrusted source:', {fromTrustedHost, data: evt.data, type: evt.data.type, evt});
      return;
    }

    if (evt.data.type === 'integration:hello') {
      this.setState({
        port: evt.ports[0]
      });
    }
  }


  render() {
    return (this.state.port ? (
      <LoggedMessageChannel messageChannel={this.state.port} token={this.state.token} proctoring_handle={this.state.proctoring_handle}/>
    ) : (
      <></>
    ))
  }
}